import {
  ContentXItemsRoute_default
} from "./chunk-K6UULPEB.js";
import {
  ItemsItemModel,
  ItemsModel
} from "./chunk-CFNVLRLL.js";

// src/ContentX/Items/ContentXItems.ts
import axios from "axios";
var AesirxContentXItemsApiService = class {
  constructor() {
    this.route = null;
    // List Page
    this.getList = async (filter = {}) => {
      try {
        const data = await this.route.getList(filter);
        let results = null;
        let pagination = null;
        if (data) {
          results = new ItemsModel(data);
        }
        pagination = {
          page: data.page,
          pageLimit: data.pageLimit,
          totalPages: data.totalPages,
          totalItems: data.totalItems,
          limitStart: data.limitstart
        };
        return { results, pagination };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.deleteItems = async (data) => {
      try {
        const response = await this.route.deleteItems(data);
        if (response) {
          return response;
        } else {
          return false;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.toggleFeatured = async () => {
      try {
        const response = true;
        if (response) {
          return response;
        } else {
          return false;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    // Create || Edit Page
    this.getFields = async (contentTypeId) => {
      try {
        const response = await this.route.getFields(contentTypeId);
        if (response) {
          return response;
        } else {
          return false;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.createItem = async (data) => {
      try {
        const result = await this.route.createItem(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.updateItem = async (data) => {
      try {
        const result = await this.route.updateItem(data);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDetail = async (id) => {
      try {
        const data = await this.route.getDetail(id);
        let results = null;
        if (data) {
          results = new ItemsItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new ContentXItemsRoute_default();
  }
};

export {
  AesirxContentXItemsApiService
};
