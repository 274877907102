import {
  VariantItemModel
} from "./chunk-YY3IZ52J.js";
import {
  PimVariantRoute_default
} from "./chunk-NQJL6XWD.js";

// src/Pim/PimVariant/PimVariant.ts
import axios from "axios";
var AesirxPimVariantApiService = class {
  constructor() {
    this.route = null;
    this.create = async (data) => {
      try {
        const result = await this.route.create(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.update = async (data) => {
      try {
        const result = await this.route.update(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getVariantDetail = async (id = 0) => {
      try {
        const data = await this.route.getVariantDetail(id);
        if (data) {
          return new VariantItemModel(data).toJSON();
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new PimVariantRoute_default();
  }
};

export {
  AesirxPimVariantApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
