import {
  BI_BROWSERS_FIELD_KEY,
  BI_CITIES_FIELD_KEY,
  BI_COUNTRIES_FIELD_KEY,
  BI_DEVICES_FIELD_KEY,
  BI_DOMAIN_FIELD_KEY,
  BI_EVENTS_FIELD_KEY,
  BI_FLOW_DETAIL_KEY,
  BI_LANGUAGES_FIELD_KEY,
  BI_PAGES_FIELD_KEY,
  BI_SUMMARY_FIELD_KEY,
  BI_VISITORS_FIELD_KEY,
  BI_VISITOR_FIELD_KEY
} from "./chunk-RUUEJ6Q5.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Bi/BiModel.ts
var DomainModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.result.map((element) => {
        return new DomainItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var DomainItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.name = null;
    this.domain = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_DOMAIN_FIELD_KEY.NAME]: this.name,
        [BI_DOMAIN_FIELD_KEY.DOMAIN]: this.domain
      };
    };
    if (entity) {
      this.name = entity[BI_DOMAIN_FIELD_KEY.NAME] ?? "";
      this.domain = entity[BI_DOMAIN_FIELD_KEY.DOMAIN] ?? "";
    }
  }
};
var VisitorsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new VisitorsItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var VisitorsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.visits = null;
    this.date = null;
    this.total_page_views = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_VISITORS_FIELD_KEY.VISITS]: this.visits,
        [BI_VISITORS_FIELD_KEY.DATE]: this.date,
        [BI_VISITORS_FIELD_KEY.TOTAL_PAGE_VIEWS]: this.total_page_views
      };
    };
    if (entity) {
      this.visits = entity[BI_VISITORS_FIELD_KEY.VISITS] ?? "";
      this.date = entity[BI_VISITORS_FIELD_KEY.DATE] ?? "";
      this.total_page_views = entity[BI_VISITORS_FIELD_KEY.TOTAL_PAGE_VIEWS] ?? "";
    }
  }
};
var SummaryModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new SummaryItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var SummaryItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var MetricsModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var VisitorModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new VisitorItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var VisitorItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.start_date = null;
    this.end_date = null;
    this.event_name = null;
    this.event_type = null;
    this.attributes = null;
    this.url = null;
    this.referer = null;
    this.flow_id = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_VISITOR_FIELD_KEY.EVENT_NAME]: this.event_name,
        [BI_VISITOR_FIELD_KEY.EVENT_TYPE]: this.event_type,
        [BI_VISITOR_FIELD_KEY.START_DATE]: this.start_date,
        [BI_VISITOR_FIELD_KEY.END_DATE]: this.end_date,
        [BI_VISITOR_FIELD_KEY.ATTRIBUTES]: this.attributes,
        [BI_VISITOR_FIELD_KEY.URL]: this.url,
        [BI_VISITOR_FIELD_KEY.REFERER]: this.referer,
        [BI_VISITOR_FIELD_KEY.FLOW_ID]: this.flow_id
      };
    };
    if (entity) {
      this.start_date = entity[BI_VISITOR_FIELD_KEY.START_DATE] ?? "";
      this.end_date = entity[BI_VISITOR_FIELD_KEY.END_DATE] ?? "";
      this.event_name = entity[BI_VISITOR_FIELD_KEY.EVENT_NAME] ?? "";
      this.event_type = entity[BI_VISITOR_FIELD_KEY.EVENT_TYPE] ?? "";
      this.attributes = entity[BI_VISITOR_FIELD_KEY.ATTRIBUTES] ?? [];
      this.url = entity[BI_VISITOR_FIELD_KEY.URL] ?? "";
      this.referer = entity[BI_VISITOR_FIELD_KEY.REFERER] ?? "";
      this.flow_id = entity[BI_VISITOR_FIELD_KEY.FLOW_ID] ?? "";
    }
  }
};
var FlowItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.uuid = null;
    this.ip = null;
    this.user_agent = null;
    this.device = null;
    this.browser_name = null;
    this.browser_version = null;
    this.domain = null;
    this.lang = null;
    this.start = null;
    this.end = null;
    this.geo = null;
    this.events = [];
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_FLOW_DETAIL_KEY.UUID]: this.uuid,
        [BI_FLOW_DETAIL_KEY.IP]: this.ip,
        [BI_FLOW_DETAIL_KEY.USER_AGENT]: this.user_agent,
        [BI_FLOW_DETAIL_KEY.DEVICE]: this.device,
        [BI_FLOW_DETAIL_KEY.BROWSER_NAME]: this.browser_name,
        [BI_FLOW_DETAIL_KEY.DOMAIN]: this.domain,
        [BI_FLOW_DETAIL_KEY.LANG]: this.lang,
        [BI_FLOW_DETAIL_KEY.START]: this.start,
        [BI_FLOW_DETAIL_KEY.END]: this.end,
        [BI_FLOW_DETAIL_KEY.GEO]: this.geo,
        [BI_FLOW_DETAIL_KEY.EVENTS]: this.events
      };
    };
    if (entity) {
      this.uuid = entity[BI_FLOW_DETAIL_KEY.UUID] ?? "";
      this.ip = entity[BI_FLOW_DETAIL_KEY.IP] ?? "";
      this.user_agent = entity[BI_FLOW_DETAIL_KEY.USER_AGENT] ?? "";
      this.device = entity[BI_FLOW_DETAIL_KEY.DEVICE] ?? "";
      this.browser_name = entity[BI_FLOW_DETAIL_KEY.BROWSER_NAME] ?? "";
      this.domain = entity[BI_FLOW_DETAIL_KEY.DOMAIN] ?? "";
      this.lang = entity[BI_FLOW_DETAIL_KEY.LANG] ?? "";
      this.start = entity[BI_FLOW_DETAIL_KEY.START] ?? "";
      this.end = entity[BI_FLOW_DETAIL_KEY.END] ?? "";
      this.geo = entity[BI_FLOW_DETAIL_KEY.GEO] ?? "";
      this.events = entity[BI_FLOW_DETAIL_KEY.EVENTS] ?? [];
    }
  }
};
var DevicesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new DevicesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var DevicesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.device = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_DEVICES_FIELD_KEY.DEVICE]: this.device,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.device = entity[BI_DEVICES_FIELD_KEY.DEVICE] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var CountriesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new CountriesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var CountriesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.country_name = null;
    this.country_code = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_COUNTRIES_FIELD_KEY.COUNTRY_NAME]: this.country_name,
        [BI_COUNTRIES_FIELD_KEY.COUNTRY_CODE]: this.country_code,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.country_name = entity[BI_COUNTRIES_FIELD_KEY.COUNTRY_NAME] ?? "";
      this.country_code = entity[BI_COUNTRIES_FIELD_KEY.COUNTRY_CODE] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var CitiesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new CitiesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var CitiesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.city = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_CITIES_FIELD_KEY.CITY]: this.city,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.city = entity[BI_CITIES_FIELD_KEY.CITY] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var BrowsersModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new BrowsersItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var BrowsersItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.browser_name = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_BROWSERS_FIELD_KEY.BROWSER_NAME]: this.browser_name,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.browser_name = entity[BI_BROWSERS_FIELD_KEY.BROWSER_NAME] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var LanguagesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new LanguagesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var LanguagesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.lang = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_LANGUAGES_FIELD_KEY.LANG]: this.lang,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.lang = entity[BI_LANGUAGES_FIELD_KEY.LANG] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var PagesModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new PagesItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var PagesItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.url = null;
    this.number_of_visitors = null;
    this.number_of_page_views = null;
    this.number_of_unique_page_views = null;
    this.average_session_duration = null;
    this.number_of_pages_per_session = null;
    this.bounce_rate = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_PAGES_FIELD_KEY.URL]: this.url,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS]: this.number_of_visitors,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS]: this.number_of_page_views,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS]: this.number_of_unique_page_views,
        [BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION]: this.average_session_duration,
        [BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION]: this.number_of_pages_per_session,
        [BI_SUMMARY_FIELD_KEY.BOUNCE_RATE]: this.bounce_rate
      };
    };
    if (entity) {
      this.url = entity[BI_PAGES_FIELD_KEY.URL] ?? "";
      this.number_of_visitors = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_VISITORS] ?? "";
      this.number_of_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGE_VIEWS] ?? "";
      this.number_of_unique_page_views = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_UNIQUE_PAGE_VIEWS] ?? "";
      this.average_session_duration = entity[BI_SUMMARY_FIELD_KEY.AVERAGE_SESSION_DURATION] ?? "";
      this.number_of_pages_per_session = entity[BI_SUMMARY_FIELD_KEY.NUMBER_OF_PAGES_PER_SESSION] ?? "";
      this.bounce_rate = entity[BI_SUMMARY_FIELD_KEY.BOUNCE_RATE] ?? "";
    }
  }
};
var EventsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = null;
    if (entities) {
      this.items = entities.collection.map((element) => {
        return new EventsItemModel(element);
      });
      this.items.pagination = this.getBiPagination();
    }
  }
};
var EventsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.event_name = null;
    this.event_type = null;
    this.total_visitor = null;
    this.date = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [BI_EVENTS_FIELD_KEY.EVENT_NAME]: this.event_name,
        [BI_EVENTS_FIELD_KEY.EVENT_TYPE]: this.event_type,
        [BI_EVENTS_FIELD_KEY.TOTAL_VISITOR]: this.total_visitor,
        [BI_EVENTS_FIELD_KEY.DATE]: this.date
      };
    };
    if (entity) {
      this.event_name = entity[BI_EVENTS_FIELD_KEY.EVENT_NAME] ?? "";
      this.event_type = entity[BI_EVENTS_FIELD_KEY.EVENT_TYPE] ?? "";
      this.total_visitor = entity[BI_EVENTS_FIELD_KEY.TOTAL_VISITOR] ?? "";
      this.date = entity[BI_EVENTS_FIELD_KEY.DATE] ?? "";
    }
  }
};

export {
  DomainModel,
  VisitorsModel,
  SummaryModel,
  SummaryItemModel,
  MetricsModel,
  VisitorModel,
  VisitorItemModel,
  FlowItemModel,
  DevicesModel,
  CountriesModel,
  CitiesModel,
  BrowsersModel,
  LanguagesModel,
  PagesModel,
  EventsModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
