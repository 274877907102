import {
  GoogleDataRoute_default
} from "./chunk-RGS3RAQ4.js";

// src/GoogleData/GoogleData.ts
var AesirxGoogleDataApiService = class {
  constructor() {
    this.route = null;
    this.route = new GoogleDataRoute_default();
  }
  async getSearchLocationFromGoogleData(key) {
    try {
      return await this.route.getSearchLocationFromGoogleDataRequest(key);
    } catch (error) {
      return error;
    }
  }
  async getInterestsFromGoogleData(key) {
    try {
      return await this.route.getInterestsFromGoogleDataRequest(key);
    } catch (error) {
      return error;
    }
  }
};

export {
  AesirxGoogleDataApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
