import {
  ContactItemModel,
  StatusItemModel
} from "./chunk-SHFIOV66.js";
import {
  CrmContactRoute_default
} from "./chunk-XPIVBAES.js";

// src/Crm/CrmContact/CrmContact.ts
import axios from "axios";
var AesirxCrmContactApiService = class {
  constructor() {
    this.route = null;
    this.create = async (data) => {
      try {
        const dataToSubmit = false ? data : ContactItemModel.__transformItemToApiOfCreation(data);
        const result = await this.route.create(dataToSubmit);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.update = async (data) => {
      try {
        const dataToSubmit = false ? data : ContactItemModel.__transformItemToApiOfUpdation(data);
        const result = await this.route.update(dataToSubmit);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDetail = async (id = 0) => {
      try {
        const data = await this.route.getDetail(id);
        let results = null;
        if (data) {
          results = new ContactItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getList = async (filter) => {
      try {
        const data = await this.route.getList(filter);
        let listItems = null;
        let pagination = null;
        if (data?._embedded) {
          listItems = await Promise.all(
            data._embedded.item.map(async (o) => {
              return new ContactItemModel(o);
            })
          );
        }
        pagination = {
          page: data.page,
          pageLimit: data.pageLimit,
          totalPages: data.totalPages,
          totalItems: data.totalItems,
          limitStart: data.limitstart
        };
        return {
          listItems: listItems ?? [],
          pagination: pagination ?? {}
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.updateStatus = async (arr, status) => {
      try {
        const result = await this.route.updateStatus(arr, status);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.delete = async (ids) => {
      try {
        const result = await this.route.delete(ids);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getStatusList = async () => {
      try {
        const data = await this.route.getStatusList();
        let statusListItems = null;
        if (data?.result) {
          statusListItems = await Promise.all(
            data.result.map(async (o) => {
              return new StatusItemModel(o);
            })
          );
        }
        return {
          statusListItems: statusListItems ?? []
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new CrmContactRoute_default();
  }
};

export {
  AesirxCrmContactApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
