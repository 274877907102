import {
  CategoriesItemModel,
  CategoryModel
} from "./chunk-5JL66X3A.js";
import {
  ContentXCategoriesRoute_default
} from "./chunk-XTNI6U43.js";

// src/ContentX/Categories/ContentXCategories.ts
import axios from "axios";
var AesirxContentXCategoryApiService = class {
  constructor() {
    this.route = null;
    this.getList = async (filters) => {
      try {
        const data = await this.route.getList(filters);
        let results = null;
        let pagination = null;
        if (data) {
          results = new CategoryModel(data);
        }
        pagination = {
          page: data.page,
          pageLimit: data.pageLimit,
          totalPages: data.totalPages,
          totalItems: data.totalItems,
          limitStart: data.limitstart
        };
        return { results, pagination };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.create = async (data) => {
      try {
        const result = await this.route.create(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.update = async (data) => {
      try {
        const result = await this.route.update(data);
        if (result) {
          return result.result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.delete = async (id) => {
      try {
        const result = await this.route.delete(id);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDetail = async (id = 0) => {
      try {
        const data = await this.route.getDetail(id);
        let results = null;
        if (data) {
          results = new CategoriesItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new ContentXCategoriesRoute_default();
  }
};

export {
  AesirxContentXCategoryApiService
};
