import {
  FacebookDataRoute_default
} from "./chunk-KN5FCIOE.js";
import {
  Storage
} from "./chunk-GYE2SV7H.js";
import {
  AUTHORIZATION_KEY
} from "./chunk-HYSYCQSM.js";

// src/FacebookData/FacebookData.ts
import axios from "axios";
var AesirxFacebookDataApiService = class {
  constructor() {
    this.route = null;
    this.accessToken = Storage.getItem(AUTHORIZATION_KEY.FACEBOOK_ADS_APP_ACCESS_TOKEN);
    this.facebookGraphApiDomain = "graph.facebook.com";
    this.facebookGraphApiVersion = "v11.0";
    this.route = new FacebookDataRoute_default();
  }
  async getAdPreviewFromFacebookData(creative, pageId, organisationId) {
    try {
      return await this.route.getAdPreviewFromFacebookDataRequest(creative, pageId, organisationId);
    } catch (error) {
      return error;
    }
  }
  async getLocationsFromFacebookData(keyword = "") {
    const endpoint = "search?type=adGeoLocation&q=" + keyword + "&limit=1000";
    try {
      const { data } = await axios({
        url: "//" + this.facebookGraphApiDomain + "/" + this.facebookGraphApiVersion + "/" + endpoint + "&access_token=" + this.accessToken,
        method: "GET"
      });
      return data;
    } catch (error) {
    }
  }
  async getDemographicsFromFacebookData() {
    const endpoint = "search?type=adTargetingCategory&class=demographics";
    try {
      const { data } = await axios({
        url: "//" + this.facebookGraphApiDomain + "/" + this.facebookGraphApiVersion + "/" + endpoint + "&access_token=" + this.accessToken,
        method: "GET"
      });
      return data;
    } catch (error) {
    }
  }
  async getInterestsFromFacebookData() {
    const endpoint = "search?type=adTargetingCategory&class=interests";
    try {
      const { data } = await axios({
        url: "//" + this.facebookGraphApiDomain + "/" + this.facebookGraphApiVersion + "/" + endpoint + "&access_token=" + this.accessToken,
        method: "GET"
      });
      return data;
    } catch (error) {
    }
  }
  async getBehaviorsFromFacebookData() {
    const endpoint = "search?type=adTargetingCategory&class=behaviors";
    try {
      const { data } = await axios({
        url: "//" + this.facebookGraphApiDomain + "/" + this.facebookGraphApiVersion + "/" + endpoint + "&access_token=" + this.accessToken,
        method: "GET"
      });
      return data;
    } catch (error) {
    }
  }
};

export {
  AesirxFacebookDataApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
