import {
  Instance_default
} from "./chunk-6SWJHUPL.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/ContentX/Items/ContentXItemsRoute.ts
var CmsItemsRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getList = (filters) => {
      const buildFilters = this.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "item_with_org_check_aesirx_content_68",
          ...buildFilters
        })
      );
    };
    this.getDetail = (id = 0) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "item_with_org_check_aesirx_content_68",
          id
        })
      );
    };
    this.createItem = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "reditem",
          view: "item_with_org_check_aesirx_content_68"
        }),
        data
      );
    };
    this.updateItem = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: "reditem",
          view: "item_with_org_check_aesirx_content_68"
        }),
        data
      );
    };
    this.deleteItems = (ids) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: "reditem",
          view: "item_with_org_check_aesirx_content_68"
        }),
        {
          data: { ids: Array.isArray(ids) ? ids : [ids] }
        }
      );
    };
    this.toggleFeatured = (id, isFeatured) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "reditem",
          id,
          isFeatured
        })
      );
    };
    this.createFilters = (filters) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object") {
          switch (value.type) {
            case "custom_fields":
              buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
              break;
            case "filter":
              buildFilter["filter[" + key + "]"] = value.value;
              break;
            default:
              break;
          }
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
  }
};
var ContentXItemsRoute_default = CmsItemsRoute;

export {
  ContentXItemsRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
