import {
  InstanceServiceApi_default
} from "./chunk-GZE7VQSK.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/GoogleData/GoogleDataRoute.ts
var GoogleDataRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getSearchLocationFromGoogleDataRequest = (key) => {
      return InstanceServiceApi_default.get("/api/google-ad/search-locations?q=" + key);
    };
    this.getInterestsFromGoogleDataRequest = (key) => {
      return InstanceServiceApi_default.get("/api/google-ad/search-interests?q=" + key);
    };
  }
};
var GoogleDataRoute_default = GoogleDataRoute;

export {
  GoogleDataRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
