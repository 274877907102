import {
  Instance_default
} from "./chunk-6SWJHUPL.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/Pim/PimVariant/PimVariantRoute.ts
var PimVariantRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem-item_variant_53";
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option
        }),
        data
      );
    };
    this.update = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: this.option
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.getVariantDetail = (id = 0) => {
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          id
        })
      );
    };
  }
};
var PimVariantRoute_default = PimVariantRoute;

export {
  PimVariantRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
