import {
  Instance_default
} from "./chunk-6SWJHUPL.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/OrganisationChannel/OrganisationChannelRoute.ts
var OrganisationChannelRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getProjectChannelItemRequest = (itemId) => Instance_default.get(
      this.createRequestURL({
        option: "organisation_channel",
        id: itemId
      })
    );
    this.getProjectChannelsRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "organisation_channel",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    /**
     *
     * @param data
     */
    this.createProjectChannelRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "organisation_channel"
      }),
      data
    );
    this.postToFanpageRequest = (itemId, content, channelType) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "organisation_channel",
          organisationId: itemId,
          content,
          channelType,
          task: "post"
        }),
        {
          organisationId: itemId,
          content,
          channelType
        }
      );
    };
    /**
     *
     * @param data
     */
    this.updateProjectChannelRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "organisation_channel"
      }),
      data
    );
    /**
     *
     * @param organisationId
     */
    this.deleteProjectChannelRequest = (itemId) => {
      const ids = itemId.split(",");
      if (ids.length < 2) {
        return Instance_default.delete(
          this.createRequestURL({
            option: "projectpersona",
            id: itemId
          })
        );
      } else {
        return Instance_default.post(
          this.createRequestURL({
            option: "projectpersona",
            task: "deleteAll"
          }),
          {
            id: itemId
          }
        );
      }
    };
    this.getLoginUrl = (channelType) => {
      const url = this.createRequestURL({
        option: "organisation_channel",
        channelType,
        task: "getLoginUrl"
      });
      return Instance_default.get(url);
    };
    this.checkConnectStatusChannel = (channelType) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          channelType,
          task: "getCheckConnectedChannel"
        })
      );
    };
    this.getListFanpageRequest = (channelType) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          task: "getListFanPages",
          channelType
        })
      );
    };
    this.connectFanpageRequest = (channelUniqueName, pageId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          pageId,
          task: "selectPageToConnect",
          channelType: channelUniqueName
        })
      );
    };
    this.disconnectFanpageRequest = (channelUniqueName, pageId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          pageId,
          task: "selectPageToDisconnect",
          channelType: channelUniqueName
        })
      );
    };
    this.getListAdAccountsRequest = (channelType) => {
      const url = this.createRequestURL({
        option: "organisation_channel",
        channelType,
        task: "getListAdAccounts"
      });
      return Instance_default.get(url);
    };
    this.connectAdAccountRequest = (accountId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          accountId,
          task: "selectAdAccountToConnect"
        })
      );
    };
    this.doLoginCMSRequest = (dataPost) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          task: "doLoginCMS",
          ...dataPost
        })
      );
    };
    this.doPostContentToCMSRequest = (dataPost) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          task: "post",
          ...dataPost
        })
      );
    };
    this.getProjectChannelsByorganisationIdRequest = (organisationId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          task: "getProjectChannelByorganisationId",
          organisationId
        })
      );
    };
    this.onConnectChannelSuccessRequest = (channelType, tokenData) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "organisation_channel",
          task: "onConnectChannelSuccess"
        }),
        { channelType, tokenData }
      );
    };
    this.disconnectChannelRequest = (channelName) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "organisation_channel",
          task: "disconnectChannel",
          channelName
        })
      );
    };
    this.getChannels = () => Instance_default.get(
      this.createRequestURL({
        option: "organisation_channel",
        task: "getChannels"
      })
    );
    this.reconnectChannel = (channelName, channelId) => Instance_default.get(
      this.createRequestURL({
        option: "organisation_channel",
        task: "reconnectChannel",
        channelName,
        channelId
      })
    );
    this.disconnectChannel = (channelName, channelId) => Instance_default.get(
      this.createRequestURL({
        option: "organisation_channel",
        task: "disconnectChannel",
        channelName,
        channelId
      })
    );
    this.removeChannel = (channelName, channelIds) => {
      let task = "removeChannel";
      let params = [{ channelId: channelIds }];
      if (Array.isArray(channelIds)) {
        task = "bulkRemoveChannel";
        params = [{ "channelIds[]": channelIds }];
      }
      return Instance_default.post(
        this.createRequestURL({
          option: "organisation_channel",
          task,
          channelName,
          ...Object.assign({}, ...params)
        })
      );
    };
  }
};
var OrganisationChannelRoute_default = OrganisationChannelRoute;

export {
  OrganisationChannelRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
