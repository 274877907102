import {
  InstanceServiceApi_default
} from "./chunk-GZE7VQSK.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/FacebookData/FacebookDataRoute.ts
var FacebookDataRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getUserAccountDataFromFacebookDataRequest = (organisationId) => {
      return InstanceServiceApi_default.get(
        "/api/facebook-data/user-account-data?organisation_id=" + organisationId
      );
    };
    this.getAdPreviewFromFacebookDataRequest = (creative, pageId, organisationId) => {
      return InstanceServiceApi_default.get(
        "/api/facebook-ad-preview?creative=" + creative + "&page_id=" + pageId + "&organisation_id=" + organisationId
      );
    };
    this.getSearchLocationFromFacebookDataRequest = (key) => {
      return InstanceServiceApi_default.get(
        "/api/facebook-data/search-locations?q=" + key + '&location_types=["country","city"]'
      );
    };
    this.getDemographicsFromFacebookDataRequest = () => {
      return InstanceServiceApi_default.get("/api/facebook-data/get-demographics");
    };
    this.getInterestsFromFacebookDataRequest = () => {
      return InstanceServiceApi_default.get("/api/facebook-data/get-interests");
    };
    this.getBehaviorsFromFacebookDataRequest = () => {
      return InstanceServiceApi_default.get("/api/facebook-data/get-behaviors");
    };
  }
};
var FacebookDataRoute_default = FacebookDataRoute;

export {
  FacebookDataRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
