import {
  checkNetwork
} from "./chunk-GJGB55TS.js";
import {
  BROWSER_WALLET
} from "./chunk-LRQNUSLO.js";
import {
  useUserContext
} from "./chunk-KHCA6UHX.js";
import {
  require_dist
} from "./chunk-TISSHJF5.js";
import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  __toESM
} from "./chunk-G2CEMTG6.js";

// src/providers/web3.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
var import_react_secure_storage = __toESM(require_dist());
import {
  WithWalletConnector,
  useConnect,
  useConnection,
  MAINNET,
  TESTNET,
  useGrpcClient
} from "@concordium/react-components";
import { toast } from "react-toastify";
var Web3Context = createContext({
  account: ""
});
var Web3ContextProvider = ({ children, autoLoad }) => {
  return /* @__PURE__ */ React.createElement(
    WithWalletConnector,
    {
      network: process.env.NEXT_PUBLIC_CONCORDIUM_NETWORK === "mainnet" ? MAINNET : TESTNET
    },
    (props) => /* @__PURE__ */ React.createElement(Web3ContextApp, { ...props, autoLoad }, children)
  );
};
var Web3ContextApp = ({ children, ...props }) => {
  const {
    activeConnectorError,
    network,
    connectedAccounts,
    genesisHashes,
    activeConnector,
    setActiveConnectorType,
    autoLoad = true
  } = props;
  const { setConnection, account, connection, genesisHash } = useConnection(
    connectedAccounts,
    genesisHashes
  );
  const { connect, connectError, isConnecting } = useConnect(activeConnector, setConnection);
  const [, setRpcGenesisHash] = useState();
  const { preregistration } = useUserContext();
  useEffect(() => {
    if (activeConnector) {
      connect();
    }
  }, [activeConnector, connect]);
  useEffect(() => {
    if (connectError) {
      notify(connectError, "error");
    }
  }, [connectError]);
  useEffect(() => {
    if (activeConnectorError) {
      notify(activeConnectorError, "error");
    }
  }, [activeConnectorError]);
  useEffect(() => {
    const connection2 = import_react_secure_storage.default.getItem("concordium");
    if (connection2 === "browser" && autoLoad) {
      setActiveConnectorType(BROWSER_WALLET);
    } else if (connection2 === "walletconnect") {
    }
  }, []);
  const gRPCClient = useGrpcClient(network);
  useEffect(() => {
    if (gRPCClient) {
      setRpcGenesisHash(void 0);
      gRPCClient.getConsensusStatus().then((status) => {
        return status.genesisBlock;
      }).then(async (hash) => {
        if (!checkNetwork(hash)) {
          throw new Error(
            `Please change the network to ${process.env.NEXT_PUBLIC_CONCORDIUM_NETWORK} in Wallet`
          );
        }
        setRpcGenesisHash(hash);
      }).catch((err) => {
        setRpcGenesisHash(void 0);
        toast(err.message);
      });
    }
  }, [gRPCClient]);
  return /* @__PURE__ */ React.createElement(
    Web3Context.Provider,
    {
      value: {
        account: account ?? "",
        setActiveConnectorType,
        connection,
        isConnecting,
        gRPCClient
      }
    },
    children
  );
};
var useWeb3Context = () => useContext(Web3Context);

export {
  Web3ContextProvider,
  useWeb3Context
};
