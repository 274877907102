import {
  Instance_default
} from "./chunk-6SWJHUPL.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/Pim/PimDashboard/PimDashboardRoute.ts
var PimTagRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem-pim_dashboard";
    this.getStatisticalData = (filter = {}) => {
      const buildFilter = this.createFilter(filter);
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          task: "getStatisticalData",
          ...buildFilter
        })
      );
    };
    this.createFilter = (filter) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filter)) {
        if (Array.isArray(value)) {
          buildFilter[key + "[]"] = value;
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
  }
};
var PimDashboardRoute_default = PimTagRoute;

export {
  PimDashboardRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
