import {
  CONTENTX_ITEMS_DETAIL_FIELD_KEY
} from "./chunk-4WQQU2XI.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/ContentX/Items/ContentXItemsModel.ts
var ItemsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new ItemsItemModel(element);
      });
    }
  }
};
var ItemsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.title = null;
    this.description = null;
    this.intro_text = null;
    this.thumb_image = null;
    this.type = null;
    this.categories = null;
    this.status = null;
    this.access = null;
    this.featured = null;
    this.created_time = null;
    this.author = null;
    this.engagement = null;
    this.visits = null;
    this.languages = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.ID]: this.id,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.NAME]: this.title,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.INTRO_TEXT]: this.description,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.CONTENT]: this.intro_text,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.FEATURED_IMAGE]: this.thumb_image,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.TYPE]: this.type,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.CATEGORY]: this.categories,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.STATUS]: this.status,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.ACCESS]: this.access,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.FEATURED]: this.featured,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.START_PUBLISH]: this.created_time,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.AUTHOR]: this.author,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.ENGAGEMENT]: this.engagement,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.VISITS]: this.visits,
        [CONTENTX_ITEMS_DETAIL_FIELD_KEY.LANGUAGES]: this.languages
      };
    };
    this.transformStatus = (status) => {
      switch (status) {
        case 1:
          return "published";
        case 0:
          return "unpublished";
        case 100:
          return "draft";
        case 2:
          return "archived";
        case -2:
          return "trashed";
        default:
          return "published";
      }
    };
    if (entity) {
      this.id = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.ID] ?? "";
      this.title = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.NAME] ?? "";
      this.description = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.INTRO_TEXT] ?? "";
      this.intro_text = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.CONTENT] ?? "";
      this.thumb_image = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.FEATURED_IMAGE]?.url ?? "";
      this.type = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.TYPE] ?? "";
      this.categories = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.CATEGORY] ?? "";
      this.status = this.transformStatus(entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.STATUS]);
      this.access = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.ACCESS] ?? "";
      this.featured = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.FEATURED] ?? "";
      this.created_time = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.START_PUBLISH] ?? "";
      this.author = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.AUTHOR] ?? "";
      this.engagement = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.ENGAGEMENT] ?? "";
      this.visits = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.VISITS] ?? "";
      this.languages = entity[CONTENTX_ITEMS_DETAIL_FIELD_KEY.LANGUAGES] ?? "";
    }
  }
};

export {
  ItemsModel,
  ItemsItemModel
};
